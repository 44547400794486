<template>
    <div>
        <div class="modal_header">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="grid-content bg-purple">
                        <div class="title_h">
                            {{ $t('message.contracts') }}:
                            <el-select
                            @change="changeContract"
                            v-model="contract_id" 
                            :placeholder="$t('message.contracts_n')" filterable clearable>
                                <el-option v-for="item in contracts" :key="item.id + item.number" :label="item.number" :value="item.id">
                                    <span style="float: left">{{ item.number }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">
                                        {{ item.organization ? item.organization.company_name : '' }}
                                    </span>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="grid-content bg-purple-light">
                        <el-row class="button_r">
                           <!-- <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit">Сохранить и закрыть</el-button> -->
                            <el-button @click="resetForm('form')">{{  $t('message.close') }}</el-button>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="row">
            <div class="col-12 mt-2">
                <div class="modal-body">
                    
                    

                    <div v-loading="loadingContent">
                        <el-row v-if="contentShow" :gutter="24">
                            <el-col :span="24" class="mb-1">
                                <el-card shadow="always">
                                    <table class="table table-striped table-bordered mb-0" v-loading="loadingData">
                                        <tbody>
                                            <tr>
                                                <td>{{ $t('message.organization') }}:</td>
                                                <td>{{ $t('message.contracts_n') }}:</td>
                                                <td>{{ $t('message.deal_amount') }}:</td>
                                                <td>{{ $t('message.remaind') }}:</td>
                                            </tr>
                                            <tr>
                                                <td>{{ model.organization ?  model.organization.company_name : '-' }}</td>
                                                <td># {{ model.number }}</td>
                                                <td>{{ model.sum | formatMoney}}</td>
                                                <td>{{ model.remainder | formatMoney }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </el-card>
                            </el-col>
                            <el-col :span="24" class="mb-1">
                                <el-card shadow="always">
                                    <contractTransactions />
                                </el-card>
                            </el-col>
                            <el-col :span="24" class="mb-3">
                                <!--  -->
                                <order-table></order-table>
                                <!--  -->
                            </el-col>
                        </el-row>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {mapGetters,mapActions} from 'vuex';
    import orderTable from "./include/crm-orders";
    import contractTransactions from "./include/contract-transactions";
    import form from '@/utils/mixins/form';
    export default {
        name:'crm-transaction',
        mixins: [form],
        props: {
            reloadModel: {type: Boolean, default: true},
        },
        components:{
            orderTable,contractTransactions
        },
        data(){
            return {
                loadingData: false,
                loadingContent:false,
                organization_id: null,
                contentShow: false,
                contract_id: null
            }
        },
        watch: {
            reloadModel: {
                handler: function () {
                    if (this.reloadModel){
                        // this.loadingData = true;
                    }
                },
                deep: true
            },
            model: {
                handler: function () {
                    this.loadingData = false;
                },
                deep: true
            }
        },
        created(){
            this.loadingData = true;
            this.organizationList({contracts:true});
        },
        mounted(){},
        computed: {
            ...mapGetters({
                model: 'contracts/model',
                contracts: 'contracts/list',
                organization_list: 'organizations/list',
            }),
            contractsFunc: function() {
                return this.organization_list.filter( item => { return item.id ==  this.organization_id} )
                // let arr = this.organization_list.filter( item => { return item.id ==  this.organization_id} )
                // let s = JSON.parse( JSON.stringify( arr ));
                // console.log(s);
                
                // return s.contracts
                
            }
        },
        methods:{
            ...mapActions({
                save: 'contracts/update',
                organizationList: 'organizations/index',
                showContract: 'contracts/show',
            }),
            loadData(){
                this.loadingData = true;
            },
            changeContract(){
                if (!this.contract_id || this.contract_id == '') {
                    this.contentShow = false;
                    return;
                }
                this.contentShow = true;
                this.loadingContent = true;
                this.showContract({id: this.contract_id, transactions:true})
                    .then( res => {
                        this.contentShow = true;
                        this.loadingContent = false;
                    })
                    .catch(err => {
                        this.contentShow = false;
                        this.loadingContent = false;
                    });
            },
            resetForm(formName) {
                // this.$refs[formName].resetFields();  
                this.contentShow = false;
                this.contract_id = null;
                this.$emit('c-close',{drawer: 'drawerTransactionFind', emptyModel: 'emptyModel'});
            },
        }
    }

</script>
